var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"body"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content"},[_c('div',{staticClass:"big"},[_c('div',{staticClass:"left"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.iscon),expression:"iscon"}],staticClass:"iscontact"},[_c('div',{staticClass:"p1"},[_c('p',[_vm._v("Contact us：")]),_c('img',{attrs:{"src":require("./img/x.png"),"alt":"关闭"},on:{"click":_vm.noshowcon}})]),_c('p',{staticClass:"p2",staticStyle:{"margin-top":"10px","padding-bottom":"10px"}},[_vm._v("E-mail：kmeta@kmeta.world")])]),_c('div',{staticClass:"contact",attrs:{"id":"contact"},on:{"click":_vm.showcon,"mouseenter":_vm.showcon1,"mouseleave":_vm.noshowcon1}},[_c('img',{attrs:{"src":"http://image.kmeta.world/website/contact.png","alt":""}}),_c('p',[_vm._v("JOIN US")])])]),_vm._m(2),_c('div',{staticClass:"bigright"},[_vm._m(3),_c('div',{staticClass:"right",on:{"mouseleave":_vm.noshow}},[_c('div',{staticClass:"download",attrs:{"id":"download"},on:{"mouseenter":_vm.show}},[_c('img',{attrs:{"src":"https://image.kmeta.world/website/download1.png"}}),_c('p',{staticStyle:{"font-size":"12px","color":"#ffffff","font-weight":"bold","margin-left":"6px"}},[_vm._v(" DOWNLOAD ")])]),(_vm.isdownload)?_c('div',{staticClass:"downloadad"},[_vm._m(4)]):_vm._e(),(_vm.isdownload)?_c('div',{staticClass:"downloadios"},[_vm._m(5)]):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"head"},[_vm._v("THE PASSWORD FOR DISCOVERING WEALTH")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"first-content__bg",attrs:{"src":"https://image.kmeta.world/website/logo2.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button"},[_c('div',{staticStyle:{"width":"18px","height":"19px"}},[_c('a',{attrs:{"href":"javascript:history.go(0);"}},[_c('img',{staticStyle:{"width":"18px","height":"19px"},attrs:{"src":"https://image.kmeta.world/website/star1.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('div',{staticClass:"login"},[_c('img',{attrs:{"src":"https://image.kmeta.world/website/login1.png"}}),_c('p',{staticStyle:{"font-size":"12px","color":"#ffffff","font-weight":"bold","margin-left":"5px"}},[_vm._v(" LOG IN ")])]),_c('div',{staticClass:"downloadlogin"},[_c('p',{staticStyle:{"font-size":"16px","color":"#ffffff"}},[_vm._v("Coming soon")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"indownloadad"},[_c('img',{attrs:{"src":"https://image.kmeta.world/website/ios1.png"}}),_c('div',{staticClass:"indownloadad_text"},[_c('p',{staticStyle:{"font-size":"14px","color":"#ffffff","font-weight":"bold"}},[_vm._v(" App Store ")]),_c('p',{staticStyle:{"font-size":"10px","color":"#ffffff"}},[_vm._v("Coming soon")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"indownloadios"},[_c('img',{attrs:{"src":"https://image.kmeta.world/website/ad1.png"}}),_c('div',{staticClass:"indownloadad_text"},[_c('p',{staticStyle:{"font-size":"14px","color":"#ffffff","font-weight":"bold"}},[_vm._v(" Android ")]),_c('p',{staticStyle:{"font-size":"10px","color":"#ffffff"}},[_vm._v("Coming soon")])])])
}]

export { render, staticRenderFns }